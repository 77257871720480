import { gql } from "@apollo/client";

export const LIST_DOCUMENTS = gql`
  query ($filters: DocumentFilterInput, $offset: Int) {
    listDocuments(filter: $filters) {
      totalCount
      results(limit: 60, offset: $offset, orderBy: ["-created"]) {
        id
        company
        bookType {
          slug
        }
        isFacturx
        created
        pages {
          previewUrl
          thumbnailUrl
        }
      }
    }
  }
`;

export const LIST_BOOK_TYPES = gql`
  query ($companyID: ID!) {
    listBookTypeDocumentCount(
      filter: { status_In: [scan], company: $companyID, exclude_BookType_In: "XY" }
    ) {
      bookType
      displayName
      documentCount
      shouldBlockNeoscanDragNDrop
    }
  }
`;

export const DELETE_DOCUMENTS = gql`
  mutation ($documentsID: [ID]!) {
    deleteDocuments(input: { documents: $documentsID }) {
      success
      errors {
        field
        messages
      }
    }
  }
`;

export const UPLOAD_DOCUMENT = gql`
  mutation ($file: Upload!, $companyID: ID!, $bookType: String, $source: PageSourceType) {
    uploadMutation(
      input: { fileUpload: $file, company: $companyID, bookType: $bookType, source: $source }
    ) {
      success
      documents {
        id
        company
        bookType {
          slug
        }
        isFacturx
        created
        pages {
          previewUrl
          thumbnailUrl
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_DOCUMENTS_BOOKTYPE = gql`
  mutation ($documents: [ID]!, $bookType: ID!) {
    updateDocumentsBookOrBookType(input: { documentsIds: $documents, bookType: $bookType }) {
      success
      errors {
        field
        messages
      }
    }
  }
`;

export const SUBMIT_DOCUMENTS_TO_NEOSCAN_EXPERT = gql`
  mutation ($companyID: ID!, $comment: String, $lastMinuteA: Boolean, $lastMinuteV: Boolean) {
    submitScanClientBulk(
      input: {
        company: $companyID
        comment: $comment
        lastMinuteA: $lastMinuteA
        lastMinuteV: $lastMinuteV
      }
    ) {
      success
      errors {
        field
        messages
      }
    }
  }
`;

export const ACCEPTED_FILE_TYPES_TO_UPLOAD = gql`
  query acceptedFileTypes {
    __type(name: "AcceptedFileTypesEnum") {
      acceptedFileTypes: enumValues {
        slug: name
        value: description
      }
    }
  }
`;
